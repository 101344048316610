
export default {
  props:{
    region:{
      type:String,
      default:'uk',
    },
    bg:{
      type:String,
      default:'greybg',
    },
  },
}
